<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>巡查上报</span>
      </div>
      <!-- <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="所属区域">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="册本">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="外勤人员">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结果">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
          <el-table-column prop="result" label="状态">
            <template slot-scope="scope">
              <el-tag size="small" type="success" v-show="scope.row.result==0">正常</el-tag>
              <el-tag size="small" type="danger" v-show="scope.row.result==1">异常</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="reportPerson" label="上报人"></el-table-column>
          <el-table-column prop="source" label="上报方式">
            <template slot-scope="scope">
              <span v-show="scope.row.source==0">巡查</span>
              <span v-show="scope.row.source==1">打卡</span>
            </template>
          </el-table-column>
          <el-table-column prop="reportTime" label="上报时间">
            <template slot-scope="scope">
              {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="turnPatrol(scope.row)">转指派</div></el-dropdown-item>
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'LK-5649',
          staffName: ['张威'],
          reportTime: 1637660902000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'TP-6548',
          staffName: ['李丹'],
          reportTime: 1637466731000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '异常',
          waterMeterNo: 'QW-3456',
          staffName: ['刘娜'],
          reportTime: 1637303655000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'LF-2468',
          staffName: ['王涛'],
          reportTime: 1637195713000,
          position: '已采集',
        }
      ],
      sheshi: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData()
    },
    // 巡查转指派
    turnPatrol(row) {
      this.$router.push({ name: 'direcPatrolTurnAdd', params: {row: row}})
    },
    details(row) {
      this.$router.push({ name: 'patrolReportDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('checkReportQuery', {
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>